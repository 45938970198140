@import "core";
.section.mobilenav {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: $color-white;
	z-index: 101;
	box-shadow: $shadow-big;

	.mobilenav-wrap {
		display: flex;
		justify-content: space-between;
	}

	.mobilenav-link {
		text-align: center;
		position: relative;
		padding: 1rem 0 calc(1rem + var(--safe-area-insets-bottom, env(safe-area-inset-bottom, 0)));
		transition: opacity $transition-hover ease;

		&:disabled {
			opacity: .8;
		}

		&:before {
			@include pseudo;
			position: absolute;
			bottom: 100%;
			border: 1rem solid transparent;
			pointer-events: none;
			border-bottom-color: $color-white;
			left: 50%;
			transform: translate3d(-50%, 1rem, 0);
			opacity: 0;
			transition:
				opacity $transition-hover ease,
				transform $transition-hover ease;
		}

		&.highlight {
			.link-icon {
				color: $color-white;
				background-color: $color-primary;
			}
		}

		&.active:not(.inactive) {
			color: $color-primary;

			&:before {
				opacity: 1;
				transform: translate3d(-50%, 0, 0);
			}
		}

		strong {
			display: block;
			margin-top: .7rem;
			font-size: 1rem;
		}
	}

		.link-icon {
			width: 4rem;
			height: 4rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: $radius-small;
			font-size: 1.8rem;
			margin: 0 auto;
		}
}